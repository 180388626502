<!-- classes -->
<template>
	<div class="content has_breadcrumb">
		<base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
		<GgPage pageType="2" title="Classes">
			<template v-slot:left>
				<div class="createBtnBox" v-if="
            $buttonPermission('Class', 'Full') ||
            $buttonPermission('Class', 'Edit') ||
            $buttonPermission('Class', 'Create')
          ">
					<gg-create-button @click="createClass" label="Create class" />
				</div>
				<gg-flex-menus :menus="menuDatas" @select="menuSelect" :default-active="activeIndex" />
			</template>
			<div class="content" v-loading="loading">
				<!-- ===== -->
				<!-- 空状态 -->
				<!-- ===== -->
				<div style="text-align: center" v-if="classList.length == 0" class="edoovo-table-empty">
					<div style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            ">
						<img style="width: 100%" :src="require('@/assets/images/moments/empty.png')" />
					</div>
					<p style="font-size: 22px">No classes yet.</p>
				</div>
				<ul class="content-ul" v-if="classList.length > 0">
					<li class="item" v-for="(item, index) in classList" :key="index">
						<div class="item-top" :style="{
                backgroundImage: 'url(' + item.classPhoto + ')',
                backgroundColor: item.themeColor,
				backgroundSize: 'cover',
              }" @click="openDetail(item)">
							<el-tooltip :content="item.className" placement="top-start">
								<p class="class-name">{{ item.className }}</p>
							</el-tooltip>
							<p class="class-romm" v-if="item.room">Room：{{ item.room }}</p>
						</div>
						<div class="item-bottom">
							<div class="sub-item">
								<span class="label">Level</span><span class="value">{{ item.level || '-' }}</span>
							</div>
							<div class="sub-item">
								<span class="label">Subject</span><span class="value">{{ item.subject || '-'}}</span>
							</div>

							<div class="sub-item">
								<span class="label">Year</span>
								<span class="value">{{ item.year || '-' }}</span>
							</div>
							<div class="sub-item">
								<span class="label">Section</span><span class="value">{{ item.section ? item.section : '-' }}</span>
							</div>
						</div>
						<div class="menu-bar-right" v-if="
						($buttonPermission('Class', 'Full') ||
                $buttonPermission('Class', 'Edit'))
				&&menuBarData.length>0
				
              ">
							<gg-menu-bar>
								
								<i class="iconmore_vert-24px iconfont" />
								<template slot="menus">
									<gg-menu-bar-item v-for="menuItem in menuBarData"
										@click="menuClick(item, menuItem, index)" :key="menuItem.key" :class="{
					  disabled: menuItem.key == 'delete' && item.using,
					  line:  menuItem.key == 'edit' 
					}" v-if="menuItem.key != 'delete' || !item.using ">
										{{ menuItem.label }}
									</gg-menu-bar-item>
									<el-tooltip popper-class="edoovo-table-cell-tooltip"
										:content="`Cannot delete the class with teachers and students`"
										:visible-arrow="false" v-else-if="item.using">
										<gg-menu-bar-item :visible-arrow="false" :disabled="true">
											Delete
										</gg-menu-bar-item>
									</el-tooltip>
								</template>
							</gg-menu-bar>
						</div>
					</li>
					<edoovo-pagination
					  v-if="classTotal > pageSize"
					  @size-change="_pageSizeChange"
					  @current-change="_pageChange"
					  :current-page="pageNumber"
					  :page-size="pageSize"
					  :total="classTotal"
					/>
				</ul>
				
			</div>
			<BaseFormDialog1 v-if="isShowBaseFormDialog" v-model="isShowBaseFormDialog" :title="baseFormTitle"
				:data="dialogClassData" @submit="handleSaveClass" textReminder="* indicates a required field"
				:submitBtnTxt="submitBtnTxt">
				<template slot="renderHtml" slot-scope="scope">
					<baseGGSelect v-if="scope.data.key == 'level'" v-model="scope.data.value" ref="level"
						:placeholder="scope.data.placeholder">
						<md-option v-for="(item, index) in levelList" :value="item.value" :key="index">
							{{ item.value }}
						</md-option>
					</baseGGSelect>
					<baseGGSelect v-else-if="scope.data.key == 'subject'" v-model="scope.data.value" ref="subject"
						:placeholder="scope.data.placeholder">
						<md-option v-for="(item, index) in subjectList" :value="item.value" :key="index">
							{{ item.value }}
						</md-option>
					</baseGGSelect>
					<baseGGSelect v-else-if="scope.data.key == 'year'" v-model="scope.data.value" ref="year"
						:placeholder="scope.data.placeholder">
						<md-option v-for="(item, index) in 4" :value="currentYear + index" :key="index">
							{{ currentYear + index }}
						</md-option>
					</baseGGSelect>
					<baseGGSelect v-else-if="scope.data.key == 'room'" v-model="scope.data.value" ref="room"
						:placeholder="scope.data.placeholder">
						<md-option v-for="(item, index) in scope.data.label" :value="item" :key="index">
							{{ item }}
						</md-option>
					</baseGGSelect>
				</template>
			</BaseFormDialog1>
		</GgPage>
		<imgCutModal :visible.sync="isImgCutShow" v-if="isImgCutShow" @_uploadCallback="_uploadCallback" />
		<Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
		<!-- "Class has been moved to closed" -->
	</div>
</template>

<script>
	import {
		mapState
	} from "vuex";
	import {
		Ajax
	} from "@/common";
	export default {
		name: "classes",
		data() {
			return {
				menuDatas: [{
						name: "Active",
						key: "active"
					},
					{
						name: "Closed",
						key: "closed"
					},
				],
				breadcrumbData: [{
						routeName: "locations",
						name: "locations",
					},
					{
						routeName: "classes",
						name: "Classes",
					},
				],
				activeIndex: "active",
				menuBarData: [{
						label: "Upload photo",
						key: "upload"
					},
					{
						label: "Close",
						key: "closed"
					},
					{
						label: "Edit",
						key: "edit"
					},
					{
						label: "Delete",
						key: "delete",
						disabled: true
					},
				],
				classList: [],
				isShowBaseFormDialog: false,
				baseFormTitle: "Create new class",
				submitBtnTxt: "Create",
				renderPass: false,
				dialogClassData: [{
						placeholder: "Class name *",
						key: "className",
						value: "",
						reg: /^[\S\s]{1,40}$/,
						errorText: "Character limit of 40",
					},
					{
						placeholder: "Level",
						key: "level",
						float: true,
						value: "",
						label: ["Preschool", "English", "Maths", "Chinese"],
						renderHtml: true,
					},
					{
						placeholder: "Subject",
						key: "subject",
						float: true,
						value: "",
						label: ["Mixed", "English", "Maths", "Chinese"],
						renderHtml: true,
					},
					{
						placeholder: "Section",
						key: "section",
						value: "",
					},
					{
						placeholder: "Year",
						key: "year",
						float: true,
						value: "",
						label: ["2021", "2020", "2019", "2018"],
						renderHtml: true,
					},
					{
						placeholder: "Room",
						key: "room",
						value: "",
					},
				],
				slotData: ["level"],
				isImgCutShow: false,
				snackbar: {
					isShow: false,
					content: ""
				},
				pageNumber: 1,
				pageSize: 20,
				totalNum: 0,
				itemData: {}, // 当前点击的item信息
				loading: false,
				levelList: [],
				subjectList: [],
				currentYear: new Date().getFullYear(),
				classTotal: 0
			};
		},
		computed: {
			...mapState({
				breadcrumb: (state) => state.breadcrumb,
				user: (state) => state.user,
			}),
		},
		watch: {
			dialogClassData: {
				deep: true,
				handler() {
					this.$nextTick(() => {
						let aaa = this.slotData.every(
							(item) => this.$refs[item] && !!this.$refs[item].value
						);
						this.renderPass = aaa;
						console.log("renderPass:", this.renderPass);
					});
				},
			},
		},
		created() {
			setTimeout(() => {
				if (sessionStorage.getItem('delete') == 'class') {
					this.handleSuspend("Class has been deleted");
					sessionStorage.setItem('delete', '')
				}
			}, 1000)
			this._getData();
			this.filterMenu();
			this._getSettingList("classes", "level", "levelList");
			this._getSettingList("classes", "subject", "subjectList");
		},
		methods: {
			_getSettingList(dataLevel, dataType, datalistName) {
				let data = {
					userId: this.user.userId,
					dataLevel,
					dataType,
				};
				this.loading = true;
				Ajax.post("/usermanage/setting/getList", data)
					.then((res) => {
						let response = res.data;
						this.loading = false;
						this[datalistName] = response || [];
					})
					.catch(() => {
						this.loading = false;
					});
			},
			_getData() {
				var data = {
					companyId: this.user.companyId,
					locationId: this.user.locationId,
					status: this.activeIndex,
					size: 20,
					current: this.pageNumber,
				};
				this.loading = true;
				Ajax.post("/usermanage/class/getList", data).then((res) => {
					this.isShowBaseFormDialog = false;
					this.classList = res.data.records || [];
					this.classTotal = res.data.total || 0
					this.loading = false;
					this.filterMenu();
				});
			},
			_pageChange(val) {
				this.pageNumber = val;
				this._getData();
			},
			_pageSizeChange(val) {
				this.pageSize = val;
				this._getData();
			},
			menuSelect(key, item) {
				this.activeIndex = key;
				if (key === "closed") {
					this.menuBarData = [{
							label: "Restore",
							key: "restore"
						},
						{
							label: "Delete",
							key: "delete"
						},
					];
				} else {
					this.menuBarData = [{
							label: "Upload photo",
							key: "upload"
						},
						{
							label: "Close",
							key: "closed"
						},
						{
							label: "Edit",
							key: "edit"
						},
						{
							label: "Delete",
							key: "delete"
						},
					];
				}
				this._getData();
			},
			menuClick(item, menuItem, index) {
				this.$emit("menuCallback", menuItem);
				this.itemData = item;
				this.fillData();
				if (menuItem.key == "edit") {
					this.isShowBaseFormDialog = true;
					this.baseFormTitle = "Edit class";
					this.submitBtnTxt = "Save";

					this.renderPass = false;
				} else if (menuItem.key == "upload") {
					this.isImgCutShow = true;
				} else if (menuItem.key == "closed") {
					let messageObj = {
						title: "Close class?",
						desc: "Once the class is closed, you will not be able to operate the class for students in this class unless you restore it.",
						cancelTxt: "Cancel",
						confirmTxt: "Close",
						showClose: false,
					};
					let callback = () => {
						this.handleUpdateClass("closed");
					};
					this.openMessageBox(messageObj, callback);
				} else if (menuItem.key == "restore") {
					let messageObj = {
						title: "Restore class?",
						desc: "Once restored, the class will move to active.",
						cancelTxt: "Cancel",
						confirmTxt: "Restore",
					};
					let callback = () => {
						this.handleUpdateClass("active");
					};
					this.openMessageBox(messageObj, callback);
				} else if (menuItem.key == "delete") {
					let messageObj = {
						title: "Delete class?",
						desc: "Once deleted, you will not be able to recover the data.",
						cancelTxt: "Cancel",
						confirmTxt: "Delete",
					};
					let callback = () => {
						this.handleUpdateClass("delete");
					};
					this.openMessageBox(messageObj, callback);
				}
			},
			filterMenu() {
				let menuBarData = this.menuBarData;
				if (this.$buttonPermission("Class", "Full")) {
					this.menuBarData = menuBarData;
				} else if (this.$buttonPermission("Class", "Edit")) {
					this.menuBarData = this.menuBarData.filter(
						(item) => item.key !== "delete" && item.key !== "closed" && item.key!== 'restore'
					);
					console.log(this.menuBarData)
				}
			},
			openDetail(classItem) {
				// 跳转班级详情页之前设置面包屑数据
				let schoolName = this.user.schoolName;
				this.$store.commit("SET_BREADCRUMDATA", [{
						routeName: "locations",
						name: schoolName,
					},
					{
						routeName: "classes",
						name: "classes",
					},
					{
						name: classItem.className,
					},
				]);
				this.$store.commit("SET_CLASSNAME", classItem.className);
				this.$store.commit("SET_CLASSID", classItem.id);
				this.$router.push({
					name: "classDetailInfo",
					query: {
						classId: classItem.id
					},
				});
			},
			createClass() {
				this.isShowBaseFormDialog = true;
				this.submitBtnTxt = "Create";
				this.dialogClassData.forEach((item) => {
					item.value = "";
				});
			},
			handleSaveClass() {
				if (this.submitBtnTxt === "Create") {
					this.handleAddClass();
				} else if (this.submitBtnTxt === "Save") {
					this.handleUpdateClass();
				}
			},
			fillData() {
				this.dialogClassData.forEach((item) => {
					item.value = this.itemData[item.key];
				});
			},
			handleAddClass() {
				let baseData = {
					companyId: this.user.companyId,
					locationId: this.user.locationId,
					userId: this.user.userId,
					current: 0,
					size: 20,
				};
				let formData = {
					className: "小萌",
					level: "Preschool",
					subject: "English",
					section: 2,
					year: "2021",
					room: "room123",
				};
				let data = {};
				this.dialogClassData.forEach((item) => {
					formData[item.key] = item.value;
				});
				data = Object.assign({}, baseData, formData);

				Ajax.post("/usermanage/class/saveClassV1", data).then((res) => {
					this.handleSuspend("Class has been created");
					this._getData();
				});
			},
			handleUpdateClass(status) {
				let baseData = {
					companyId: this.user.companyId,
					locationId: this.user.locationId,
					userId: this.user.userId,
					current: 0,
					size: 20,
				};
				let formData = {
					className: "小萌",
					level: "Preschool",
					subject: "English",
					section: 2,
					year: "2021",
					room: "room123",
					id: this.itemData.id,
					status,
					classPhoto: this.itemData.classPhoto,
				};
				let data = {};
				this.dialogClassData.forEach((item) => {
					formData[item.key] = item.value;
				});
				data = Object.assign({}, baseData, formData);

				Ajax.post("/usermanage/class/updateClassV1", data).then((res) => {
					if (status == 'closed') {
						this.handleSuspend("Class has been moved to closed");
					} else if (status == 'active') {
						this.handleSuspend("Class has been restored");
					} else if (status == 'delete') {
						this.handleSuspend("Class has been deleted");
					} else {
						this.handleSuspend("Class has been edited");
					}

					this._getData();
				});
			},
			openMessageBox(messageObj, callback) {
				this.$confirm(messageObj.desc, messageObj.title, {
					cancelButtonText: messageObj.cancelTxt,
					confirmButtonText: messageObj.confirmTxt,
					cancelButtonClass: "gg_cancel_button",
					confirmButtonClass: "gg_submit_button",
					customClass: "gg_confirm",
					showClose: false,
					callback: (action) => {
						if (action == "confirm") {
							callback(action);
						}
					},
				});
				//  .then(() => {
				//   this.handleSuspend("dddd");
				// });
			},
			_uploadCallback(formData, fileData) {
				formData.append("multipartFile", fileData.raw);
				Ajax.post("/media/public/file/upload", formData)
					.then((res) => {
						//
						this.$set(
							this.itemData,
							"classPhoto",
							`${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
						);
						this.handleUpdateClass();
						this.isImgCutShow = false;
					})
					.catch(() => {
						this.isImgCutShow = false;
					});
			},
			handleSuspend(content) {
				this.snackbar.content = content;
				this.snackbar.isShow = true;
			},
		},
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				let schoolName = vm.user.schoolName;
				vm.breadcrumbData[0].name = schoolName;
			});
		},
	};
</script>
<style lang='less' scoped>
	//@import url(); 引入公共css类
	.createBtnBox {
		padding-left: 8px;
		padding-bottom: 24px;
	}

	.content {
		.content-ul {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 328px;
				height: 264px;
				margin-right: 20px;
				margin-bottom: 20px;
			}

			.item {
				position: relative;
				border: 1px solid #e0e0e0;
				border-radius: 8px;
				overflow: hidden;

				.item-top {
					cursor: pointer;
					padding: 25px 16px;
					background-repeat: no-repeat;
					background-size: 328px auto;
					height: 136px;
					color: #fff;

					.class-name {
						line-height: 1;
						padding-bottom: 12px;
						font-family: OpenSans-Bold;
						font-size: 35px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.class-room {
						font-family: Roboto-medium;
						font-size: 16px;
					}
				}

				.item-bottom {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 21px 16px;
					font-family: Roboto-medium;
					font-size: 12px;
					color: #202124;

					.sub-item {
						width: 50%;
						padding-bottom: 12px;

						.label {
							display: inline-block;
							width: 60px;
						}

						.value {
							font-family: Roboto;
						}
					}
				}

				.menu-bar-right {
					position: absolute;
					right: 12px;
					bottom: 20px;
				}
			}
		}
	}

	.line {
		border-top: 1px solid #e9e9e9;
	}

	/deep/.content {
		padding-bottom: 112px;
	}
/* 	/deep/.menu_bar_button{
		.iconmore_vert-24px{
			margin-top: 7px;
			display: inline-block;
			overflow:visible;
		}
	} */
	/deep/.menu_bar {
	  margin-top: 12px;
	  width: 40px;
	  height: 40px;
	  line-height: inherit;
	  .drop_down_menus {
	    padding: 0;
	  }
	  .iconmore_vert-24px{
		  font-size: 24px;
		  line-height: 40px;
	  }
	}
	
/* 	/deep/.menu_bar .menu_bar_button:hover {
	  background: none;
	} */
</style>
